<template>
  <div class="c-app modal-vue farm-page miners-page">
    <CWrapper class="bg-bg-gray">
      <div
        class="d-flex justify-content-between align-items-start mt-4 mb-2 c-miners-sort"
      >
        <div class="c-title pb-4">{{ $t("home") }}</div>
        <form class="c-miners-filtration d-flex">
          <div class="position-relative mr-3 mb-3 mb-lg-3">
            <CCard
              @click="collapse = !collapse"
              class="myCard shadow-none m-0 p-2 text-black d-flex flex-row justify-content-between align-items-center cursor-pointer"
            >
              <span class="text-black targetCardtext">Efficiency</span>
              <img src="@/assets/icons/arrow-select.svg" alt="" class="targetCardtext" />
            </CCard>
            <CCollapse
              :show="collapse"
              class="collapseCard border-0 position-absolute w-100"
            >
              <CCard class="shadow-none border-0 p-2 mt-0 mb-0 radio_card">
                <p
                  class="d-flex align-items-center relative"
                  v-for="(obj, i) in efficiencyFilterFields"
                  :key="i"
                >
                  <input
                    type="checkbox"
                    :id="'h' + i"
                    name="efficiency"
                    class="d-inline w-auto targetCardtext"
                    :value="obj.key"
                    @change="(val) => updateEfficiency(val, i)"
                  />
                  <!--                  <span class="checkmark"></span>-->
                  <label :for="'h' + i" class="w-100 mb-0 ml-3 targetCardtext">
                    {{ obj.value }}
                    <span :class="'k' + obj.key">{{ obj.percent }}</span>
                  </label>
                </p>
              </CCard>
            </CCollapse>
          </div>
          <CSelect :options="['10', '50', '100']" @update:value="updateValue" />
        </form>
      </div>
      <CTabs
        variant="pills"
        :active-tab="currentTab === '#table' ? 1 : 0"
        @update:activeTab="updateTab"
        class="position-relative"
      >
        <CTab title="">
          <template #title="{ item }">
            <svg
              class="mr-4"
              width="22"
              height="22"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.86727 0.9H2.59937C1.66224 0.9 0.9 1.66221 0.9 2.59934V6.8673C0.9 7.80444 1.66224 8.56665 2.59937 8.56665H6.86727C7.8044 8.56665 8.56665 7.80444 8.56665 6.8673V2.59934C8.56665 1.66221 7.8044 0.9 6.86727 0.9ZM7.83333 6.8673C7.83333 7.39988 7.39989 7.8333 6.86727 7.8333H2.59937C2.06678 7.8333 1.63331 7.39988 1.63331 6.8673V2.59934C1.63331 2.06676 2.06675 1.63334 2.59937 1.63334H6.86727C7.39986 1.63334 7.83333 2.06677 7.83333 2.59934V6.8673Z"
                fill="#888888"
                stroke="#888888"
                stroke-width="0.2"
              />
              <path
                d="M6.86727 9.43333H2.59937C1.66224 9.43333 0.9 10.1955 0.9 11.1327V15.4006C0.9 16.3378 1.66224 17.1 2.59937 17.1H6.86727C7.8044 17.1 8.56665 16.3378 8.56665 15.4006V11.1327C8.56665 10.1955 7.8044 9.43333 6.86727 9.43333ZM7.83333 15.4006C7.83333 15.9332 7.39989 16.3666 6.86727 16.3666H2.59937C2.06678 16.3666 1.63331 15.9332 1.63331 15.4006V11.1327C1.63331 10.6001 2.06676 10.1666 2.59937 10.1666H6.86727C7.39986 10.1666 7.83333 10.6001 7.83333 11.1326V15.4006Z"
                fill="#888888"
                stroke="#888888"
                stroke-width="0.2"
              />
              <path
                d="M15.3985 0.9H11.1306C10.1935 0.9 9.43125 1.66221 9.43125 2.59934V6.8673C9.43125 7.80444 10.1935 8.56665 11.1306 8.56665H15.3985C16.3357 8.56665 17.0979 7.80444 17.0979 6.8673V2.59934C17.0979 1.66221 16.3357 0.9 15.3985 0.9ZM16.3646 6.8673C16.3646 7.39988 15.9311 7.8333 15.3985 7.8333H11.1306C10.598 7.8333 10.1646 7.39988 10.1646 6.8673V2.59934C10.1646 2.06676 10.598 1.63334 11.1307 1.63334H15.3985C15.9311 1.63334 16.3646 2.06676 16.3646 2.59934V6.8673Z"
                fill="#888888"
                stroke="#888888"
                stroke-width="0.2"
              />
              <path
                d="M15.3985 9.43333H11.1306C10.1935 9.43333 9.43125 10.1955 9.43125 11.1327V15.4006C9.43125 16.3378 10.1935 17.1 11.1306 17.1H15.3985C16.3357 17.1 17.0979 16.3378 17.0979 15.4006V11.1327C17.0979 10.1955 16.3357 9.43333 15.3985 9.43333ZM16.3646 15.4006C16.3646 15.9332 15.9311 16.3666 15.3985 16.3666H11.1306C10.598 16.3666 10.1646 15.9332 10.1646 15.4006V11.1327C10.1646 10.6001 10.598 10.1666 11.1307 10.1666H15.3985C15.9311 10.1666 16.3646 10.6001 16.3646 11.1326V15.4006Z"
                fill="#888888"
                stroke="#888888"
                stroke-width="0.2"
              />
            </svg>
          </template>
          <CRow>
            <CCol xl="4" lg="6" md="6" sm="12" v-for="(obj, i) in items" :key="i">
              <CCard>
                <router-link :to="'/Container/1?farm=' + obj[obj.length - 1].allInfo.id">
                  <CCardBody class="p-3">
                    <CDataTable :items="obj" :fields="fields" class="mb-0">
                      <template #info="{ index, item }">
                        <td
                          class="td-title text-main_black mb-2 d-block cursor-pointer"
                          v-if="index === 1"
                        >
                          {{ item.info }}
                        </td>
                        <td class="text-gray" v-if="index !== 1 && !item.isHeader">
                          {{ item.info }}
                        </td>
                        <td v-if="item.farms" class="td-value">
                          <CButton
                            @click.stop.prevent="
                              $store.commit('miners/triggerEdit', {
                                ...obj[obj.length - 1].allInfo,
                                page: 'farm',
                                status: 'edit',
                              })
                            "
                            class="edit-icon border-none p-0"
                          >
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 16 17"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M10.7371 1.46246C11.5826 0.617006 12.9533 0.617003 13.7988 1.46246L15.3659 3.02957C16.2114 3.87503 16.2114 5.24579 15.3659 6.09125L5.26288 16.1943C4.85687 16.6003 4.30621 16.8284 3.73204 16.8284H0.721645C0.323091 16.8284 0 16.5053 0 16.1067V13.0963C0 12.5222 0.22809 11.9715 0.634094 11.5655L10.7371 1.46246ZM12.7782 2.48302C12.4964 2.2012 12.0395 2.2012 11.7577 2.48302L10.4019 3.83876L12.9896 6.42643L14.3453 5.07069C14.6272 4.78887 14.6272 4.33195 14.3453 4.05013L12.7782 2.48302ZM11.969 7.44699L9.38138 4.85932L1.65465 12.5861C1.51932 12.7214 1.44329 12.9049 1.44329 13.0963V15.3851H3.73204C3.92343 15.3851 4.10698 15.309 4.24232 15.1737L11.969 7.44699Z"
                                fill="#888888"
                              />
                            </svg>
                          </CButton>
                          <CButton
                            class="delete-icon border-none p-0 ml-4"
                            v-if="userInfo && userInfo.user.role == 'admin'"
                            @click.stop.prevent="
                              deleteFarm(
                                obj[obj.length - 1].allInfo.id,
                                obj[obj.length - 1].allInfo
                              )
                            "
                          >
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M9 1.69565C4.96592 1.69565 1.69565 4.96592 1.69565 9C1.69565 13.0341 4.96592 16.3043 9 16.3043C13.0341 16.3043 16.3043 13.0341 16.3043 9C16.3043 4.96592 13.0341 1.69565 9 1.69565ZM1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9C17 13.4183 13.4183 17 9 17C4.58172 17 1 13.4183 1 9ZM5.97144 5.97144C6.10728 5.83561 6.32751 5.83561 6.46334 5.97144L9 8.5081L11.5367 5.97144C11.6725 5.83561 11.8927 5.83561 12.0286 5.97144C12.1644 6.10728 12.1644 6.32751 12.0286 6.46334L9.4919 9L12.0286 11.5367C12.1644 11.6725 12.1644 11.8927 12.0286 12.0286C11.8927 12.1644 11.6725 12.1644 11.5367 12.0286L9 9.4919L6.46334 12.0286C6.32751 12.1644 6.10728 12.1644 5.97144 12.0286C5.83561 11.8927 5.83561 11.6725 5.97144 11.5367L8.5081 9L5.97144 6.46334C5.83561 6.32751 5.83561 6.10728 5.97144 5.97144Z"
                                fill="#888888"
                                stroke="#888888"
                                stroke-width="0.6"
                              />
                            </svg>
                          </CButton>
                        </td>
                        <td v-if="item.isHeader" :class="getClass(item.value)">
                          {{ item.info }}: {{ item.value }}%
                        </td>
                      </template>
                      <template #value="{ item }">
                        <td v-if="item.items" class="td-value text-main_black">
                          <span class="miners-well">{{ item.items.green }}</span>
                          <span class="miners-danger">{{ item.items.red }}</span>
                          <span class="miners-total">{{ item.items.grey }}</span>
                        </td>
                        <td
                          class="td-value text-main_black table-value"
                          v-if="!item.isHeader && !item.isHashrate"
                        >
                          {{ item.value }}
                        </td>
                        <td
                          class="td-value text-main_black table-value"
                          v-if="item.isHashrate"
                        >
                          <!-- {{ convertHashrate(parseInt(item.value) * parseInt(obj[7].items.green)) }} -->
                          {{ convertHashrate(parseInt(item.value)) }}
                        </td>
                      </template>
                    </CDataTable>
                  </CCardBody>
                </router-link>
              </CCard>
            </CCol>
          </CRow>
        </CTab>
        <CTab title="" class="card-list">
          <template #title="{ item }" class="card-list">
            <svg
              width="20"
              height="20"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.2857 0H1.71428C0.767511 0 0 0.767511 0 1.71428V14.2857C0 15.2325 0.767511 16 1.71428 16H14.2857C15.2325 16 16 15.2325 16 14.2857V1.71428C16 0.767511 15.2325 0 14.2857 0ZM14.8571 14.2857C14.8571 14.6013 14.6013 14.8572 14.2857 14.8572H1.71428C1.39868 14.8572 1.14285 14.6013 1.14285 14.2857V1.71428C1.14285 1.39868 1.39868 1.14285 1.71428 1.14285H14.2857C14.6013 1.14285 14.8572 1.39868 14.8572 1.71428V14.2857H14.8571Z"
                fill="#C2C2C2"
              />
              <path
                d="M12.572 3.42853L3.20035 3.42853C2.88474 3.42853 2.62891 3.68436 2.62891 3.99997C2.62891 4.31557 2.88474 4.57141 3.20035 4.57141L12.572 4.57141C12.8876 4.57141 13.1434 4.31557 13.1434 3.99997C13.1434 3.68437 12.8876 3.42853 12.572 3.42853Z"
                fill="#C2C2C2"
              />
              <path
                d="M12.572 7.42853H3.2316C2.91599 7.42853 2.66016 7.68436 2.66016 7.99997C2.66016 8.31557 2.91599 8.57137 3.2316 8.57137H12.572C12.8876 8.57137 13.1434 8.31554 13.1434 7.99993C13.1434 7.68433 12.8876 7.42853 12.572 7.42853Z"
                fill="#C2C2C2"
              />
              <path
                d="M12.5726 11.4285H3.28238C2.96677 11.4285 2.71094 11.6844 2.71094 12C2.71094 12.3156 2.96677 12.5714 3.28238 12.5714H12.5726C12.8882 12.5714 13.144 12.3156 13.144 12C13.144 11.6844 12.8881 11.4285 12.5726 11.4285Z"
                fill="#C2C2C2"
              />
            </svg>
          </template>
          <div class="farm-list">
            <table>
              <tr>
                <th>Farm</th>
                <th>Exp. Hashrate</th>
                <th>Crt. Hashrate</th>
                <th>Efficiency</th>
                <th>Avg Temp</th>
                <th>Max Temp</th>
                <th>Active Min.</th>
                <th>Inactive Min.</th>
                <th>Total Min.</th>
              </tr>
              <tr v-for="(obj, i) in farms.data" :key="i">
                <td>
                  <router-link :to="'/Container/1?farm=' + obj.id">
                    {{ obj.name }}
                  </router-link>
                </td>
                <td>{{ convertHashrate(obj.exp_hashrate) }}</td>
                <td>{{ convertHashrate(obj.current_hashrate) }}</td>
                <td>{{ obj.efficiency }}</td>
                <td>{{ obj.avg_temperature }} C</td>
                <td>{{ obj.max_temperature }} C</td>
                <td>
                  <span class="miners-well">{{ obj.green }}</span>
                </td>
                <td>
                  <span class="miners-danger">{{ obj.red }}</span>
                </td>
                <td>
                  <span class="miners-total">{{ obj.grey }}</span>
                </td>
                <td>
                  <CButton
                    @click.stop.prevent="
                      $store.commit('miners/triggerEdit', {
                        ...obj,
                        page: 'farm',
                        status: 'edit',
                      })
                    "
                    class="edit-icon border-none p-0"
                  >
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M10.7371 1.46246C11.5826 0.617006 12.9533 0.617003 13.7988 1.46246L15.3659 3.02957C16.2114 3.87503 16.2114 5.24579 15.3659 6.09125L5.26288 16.1943C4.85687 16.6003 4.30621 16.8284 3.73204 16.8284H0.721645C0.323091 16.8284 0 16.5053 0 16.1067V13.0963C0 12.5222 0.22809 11.9715 0.634094 11.5655L10.7371 1.46246ZM12.7782 2.48302C12.4964 2.2012 12.0395 2.2012 11.7577 2.48302L10.4019 3.83876L12.9896 6.42643L14.3453 5.07069C14.6272 4.78887 14.6272 4.33195 14.3453 4.05013L12.7782 2.48302ZM11.969 7.44699L9.38138 4.85932L1.65465 12.5861C1.51932 12.7214 1.44329 12.9049 1.44329 13.0963V15.3851H3.73204C3.92343 15.3851 4.10698 15.309 4.24232 15.1737L11.969 7.44699Z"
                        fill="#888888"
                      />
                    </svg>
                  </CButton>
                  <CButton
                    class="delete-icon border-none p-0 ml-4"
                    v-if="userInfo && userInfo.user.role == 'admin'"
                    @click.stop.prevent="deleteFarm(obj.id)"
                  >
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M9 1.69565C4.96592 1.69565 1.69565 4.96592 1.69565 9C1.69565 13.0341 4.96592 16.3043 9 16.3043C13.0341 16.3043 16.3043 13.0341 16.3043 9C16.3043 4.96592 13.0341 1.69565 9 1.69565ZM1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9C17 13.4183 13.4183 17 9 17C4.58172 17 1 13.4183 1 9ZM5.97144 5.97144C6.10728 5.83561 6.32751 5.83561 6.46334 5.97144L9 8.5081L11.5367 5.97144C11.6725 5.83561 11.8927 5.83561 12.0286 5.97144C12.1644 6.10728 12.1644 6.32751 12.0286 6.46334L9.4919 9L12.0286 11.5367C12.1644 11.6725 12.1644 11.8927 12.0286 12.0286C11.8927 12.1644 11.6725 12.1644 11.5367 12.0286L9 9.4919L6.46334 12.0286C6.32751 12.1644 6.10728 12.1644 5.97144 12.0286C5.83561 11.8927 5.83561 11.6725 5.97144 11.5367L8.5081 9L5.97144 6.46334C5.83561 6.32751 5.83561 6.10728 5.97144 5.97144Z"
                        fill="#888888"
                        stroke="#888888"
                        stroke-width="0.6"
                      />
                    </svg>
                  </CButton>
                </td>
              </tr>
            </table>
          </div>
        </CTab>
      </CTabs>
      <!-- New pull request -->
      <!-- Active page: {{page}} -->
      <CPagination
        :activePage.sync="page"
        :pages="
          $store.state.miners.farms.meta ? $store.state.miners.farms.meta.last_page : 0
        "
        :doubleArrows="false"
        @update:activePage="updatePage"
        size="lg"
        align="center"
      />
    </CWrapper>
    <CModal
      title="Edit"
      color="lightGray"
      class="text-main_black bg-milk_mix"
      :show.sync="warningModal"
    >
      <CForm>
        <p class="border-0 mb-2">
          <label for="first_name">Farm name</label>
          <CInput id="location"> </CInput>
        </p>
      </CForm>
    </CModal>
    <CModal
      title="Deactivate"
      color="lightGray"
      class="text-main_black bg-milk_mix"
      :show.sync="successModal"
    >
      <CForm>
        <div class="border-0 mb-0 text-center">
          <img src="@/assets/icons/ic_success.svg" alt="" />
          <div class="mt-4">
            <p class="w-75 m-auto font-weight-normal">Deactivated</p>
          </div>
        </div>
      </CForm>
      <template #footer="{ item }">
        <span />
      </template>
    </CModal>
  </div>
</template>

<script>
import { computePercentage } from "../../api/composition/miner";
import { mapActions, mapState } from "vuex";

const templator = [
  {
    info: "Current Hashrate",
    value: "",
    key: "efficiency",
    isHeader: true,
    _classes: "header-hashrate",
  },
  { info: " ", key: "name", farms: {}, _classes: "table-none", id: 0 },
  {
    info: "Expected Hashrate:",
    key: "exp_hashrate",
    value: "",
    isHashrate: true,
  },
  {
    info: "Current Hashrate:",
    key: "current_hashrate",
    value: "",
    isHashrate: true,
  },
  { info: "Efficiency:", key: "efficiency", value: " %" },
  { info: "Avg Temp:", key: "avg_temperature", value: " C" },
  { info: "Max Temp:", key: "max_temperature", value: " C" },
  { info: "Miners", items: {}, key: "miners", _classes: "table-none" },
  { allInfo: {} },
];
const items = [];

const list = [
  {
    username: "Samppa Nori",
    registered: "2012/01/01",
    role: "Member",
    status: "Active",
  },
  {
    username: "Estavan Lykos",
    registered: "2012/02/01",
    role: "Staff",
    status: "Banned",
  },
  {
    username: "Chetan Mohamed",
    registered: "2012/02/01",
    role: "Admin",
    status: "Inactive",
  },
  {
    username: "Derick Maximinus",
    registered: "2012/03/01",
    role: "Member",
    status: "Pending",
  },
  {
    username: "Friderik Dávid",
    registered: "2012/01/21",
    role: "Staff",
    status: "Active",
  },
  {
    username: "Yiorgos Avraamu",
    registered: "2012/01/01",
    role: "Member",
    status: "Active",
  },
  {
    username: "Avram Tarasios",
    registered: "2012/02/01",
    role: "Staff",
    status: "Banned",
    _classes: "table-success",
  },
  {
    username: "Quintin Ed",
    registered: "2012/02/01",
    role: "Admin",
    status: "Inactive",
  },
  {
    username: "Enéas Kwadwo",
    registered: "2012/03/01",
    role: "Member",
    status: "Pending",
  },
  {
    username: "Agapetus Tadeáš",
    registered: "2012/01/21",
    role: "Staff",
    status: "Active",
  },
  {
    username: "Carwyn Fachtna",
    registered: "2012/01/01",
    role: "Member",
    status: "Active",
    _classes: "table-info",
  },
  {
    username: "Nehemiah Tatius",
    registered: "2012/02/01",
    role: "Staff",
    status: "Banned",
  },
  {
    username: "Ebbe Gemariah",
    registered: "2012/02/01",
    role: "Admin",
    status: "Inactive",
  },
  {
    username: "Eustorgios Amulius",
    registered: "2012/03/01",
    role: "Member",
    status: "Pending",
  },
  {
    username: "Leopold Gáspár",
    registered: "2012/01/21",
    role: "Staff",
    status: "Active",
  },
  {
    username: "Pompeius René",
    registered: "2012/01/01",
    role: "Member",
    status: "Active",
  },
  {
    username: "Paĉjo Jadon",
    registered: "2012/02/01",
    role: "Staff",
    status: "Banned",
  },
  {
    username: "Micheal Mercurius",
    registered: "2012/02/01",
    role: "Admin",
    status: "Inactive",
  },
  {
    username: "Ganesha Dubhghall",
    registered: "2012/03/01",
    role: "Member",
    status: "Pending",
  },
  {
    username: "Hiroto Šimun",
    registered: "2012/01/21",
    role: "Staff",
    status: "Active",
  },
  {
    username: "Vishnu Serghei",
    registered: "2012/01/01",
    role: "Member",
    status: "Active",
  },
  {
    username: "Zbyněk Phoibos",
    registered: "2012/02/01",
    role: "Staff",
    status: "Banned",
  },
  {
    username: "Einar Randall",
    registered: "2012/02/01",
    role: "Admin",
    status: "Inactive",
    _classes: "table-danger",
  },
  {
    username: "Félix Troels",
    registered: "2012/03/21",
    role: "Staff",
    status: "Active",
  },
  {
    username: "Aulus Agmundr",
    registered: "2012/01/01",
    role: "Member",
    status: "Pending",
  },
];

const fields = [
  {
    key: "info",
    filter: false,
    label: "",
    _classes: "p-0 border-0",
  },
  {
    key: "value",
    filter: false,
    label: "",
    _classes: "p-0 border-0",
  },
];

const fieldsList = [
  { key: "username", _style: "min-width:200px" },
  "registered",
  { key: "role", _style: "min-width:100px;" },
  { key: "status", _style: "min-width:100px;" },
  {
    key: "show_details",
    label: "",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
];

export default {
  name: "Farms",
  components: {},
  data() {
    return {
      items: items,
      templator,
      // list: list,
      list: list.map((item, id) => {
        return { ...item, id };
      }),
      fieldsList,
      successModal: false,
      fields,
      checkedValues: {},
      efficiencyFilterFields: [
        {
          key: 222,
          value: "Deactivated ",
          percent: "0(%)",
        },
        {
          key: 333,
          value: "Low ",
          percent: "0.1-85(%)",
        },
        {
          key: 444,
          value: "Medium ",
          percent: "85-95(%)",
        },
        {
          key: 555,
          value: "High ",
          percent: "95-100(%)",
        },
      ],
      collapse: false,
      details: [],
      warningModal: false,
      collapseDuration: 0,
      page: parseInt(this.$route.params.id),
      currentTab: this.$route.hash ? this.$route.hash : "#card",
    };
  },
  mounted() {
    this.$nextTick(() => {
      document.querySelector("body").addEventListener("click", (e) => {
        if (typeof e.target.className != "string") {
          return;
        }
        if (
          e.target.type !== "checkbox" &&
          !e.target.className.includes("farmCard2") &&
          !e.target.className.includes("collapseCard") &&
          !e.target.className.includes("myCard") &&
          !e.target.className.includes("farm-select") &&
          !e.target.className.includes("targetCardtext")
        ) {
          this.collapse = false;
          this.collapse2 = false;
          this.collapse3 = false;
          this.collapseEfficiency = false;
          this.collapseDuration = false;
        }
      });
    });
  },
  computed: {
    ...mapState("miners", ["allFarms", "allContainers", "farms"]),
    ...mapState("user", ["userInfo"]),
  },
  setup() {
    const getClass = computePercentage;

    return { getClass };
  },
  created() {
    this.curPage();
    this.$store
      .dispatch("miners/FILTER_FARMS", {
        body: this.$route.query,
        page: this.$route.params.id,
      })
      .then((res) => {
        this.items = this.mappedFarms(res.farms);
      });
  },
  methods: {
    ...mapActions("miners", ["CHANGE_STATUS_FARM"]),
    updatePage(e) {
      this.$router.push({
        ...this.$route,
        params: {
          id: e,
        },
      });
    },
    deleteFarm(id, obj) {
      if (+obj.green > 0 || +obj.red > 0) {
        alert(`На данной ферме ${+obj.green + +obj.red} майнеров активны`);
        return;
      }
      const isOk = confirm("Вы уверены?");
      if (isOk) {
        this.CHANGE_STATUS_FARM({
          body: {
            status: "inactive",
          },
          page: this.$route.params.id,
          id: id,
        }).then(() => {
          this.successModal = true;
        });
      }
    },
    updateTab(value) {
      this.$router.push({
        ...this.$route,
        hash: value ? "#table" : "#card",
      });
    },
    updateValue(value) {
      this.$store
        .dispatch("miners/FILTER_FARMS", {
          body: {
            ...this.$route.query,
            per_page: value,
          },
          page: this.$route.params.id,
        })
        .then((res) => {
          this.$router.push({
            ...this.$route,
            query: {
              ...this.$route.query,
              per_page: value,
            },
          });
          // this.items = this.mappedFarms(res.farms);
        });
    },
    mappedFarms(res) {
      return res.data.map((res) => {
        return this.templator.map((obj) => {
          if (obj.allInfo) {
            return { ...obj, allInfo: { ...res } };
          }
          if (obj.key === "name") {
            return {
              ...obj,
              info: res["name"],
              id: res["id"],
            };
          }
          if (obj.key === "miners") {
            return {
              ...obj,
              items: {
                ...res,
                grey: parseInt(res["red"]) + parseInt(res["green"]),
              },
            };
          }
          return { ...obj, value: res[obj.key] + obj.value };
        });
      });
    },
    curPage() {
      this.$emit("curPage", "farm");
    },
    updateEfficiency(val, i) {
      if (!this.checkedValues[i]) {
        this.checkedValues[i] = this.efficiencyFilterFields[i].key;
      } else {
        delete this.checkedValues[i];
      }

      this.$route.query.status = Object.keys(this.checkedValues).map((res) => {
        return this.checkedValues[res];
      });

      this.$store
        .dispatch("miners/FILTER_FARMS", {
          body: this.$route.query,
          page: this.$route.params.id,
        })
        .then((res) => {
          this.items = this.mappedFarms(res.farms);
        });
    },
  },
  watch: {
    "$store.state.miners.farms.data": {
      handler: function (e) {
        this.items = e.map((res) => {
          return this.templator.map((obj) => {
            if (obj.key === "name") {
              return {
                ...obj,
                info: res["name"],
              };
            }
            if (obj.allInfo) {
              return { ...obj, allInfo: { ...res } };
            }
            if (obj.key === "miners") {
              return {
                ...obj,
                items: {
                  ...res,
                  grey: parseInt(res["red"]) + parseInt(res["green"]),
                },
              };
            }
            return { ...obj, value: res[obj.key] + obj.value };
          });
        });
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="sass">

.card
  border: none
  box-shadow: 0px 8px 16px rgba(214, 210, 210, 0.24)
  border-radius: 8px
  overflow: hidden

.card .table
  td
    border: none
    padding: 0
  .td-value
    text-align: right
    span
      & + span
        margin-left: 8px
  .td-title
    font-weight: 500
    font-size: 20px

.miners
  &-well, &-danger, &-total
    padding: 4px 8px
    border-radius: 8px
    font-weight: 500
    font-size: 12px
    color: white
  &-well
    background: #09BB70
  &-danger
    background: #F42A4E
  &-total
    background: #C2C2C2

.edit-icon:hover svg path
  fill: #0F5B84

.delete-icon:hover svg path
  fill: #EC5252
  stroke: #EC5252
</style>
